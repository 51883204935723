import React, { useEffect } from "react";
import SubCard from "../../../ui-component/cards/SubCard";
import { Button, Grid, MenuItem, Select, TextField } from "@mui/material";
import axios from "axios";
import { set } from "date-fns";

function MoveOrderBulkToProductCollection() {
  const [zohoInvoiceNumber, setZohoInvoiceNumber] = React.useState("");

  const updateOrder = () => {
    axios
      .post(
        process.env.REACT_APP_PATH +
          "/products/moveCrmBulkOrderToProductOrderCollection",
        {
          zohoInvoiceNumber: zohoInvoiceNumber.trim(),
        }
      )
      .then((res) => {
        if (res.data.success) {
          alert(res.data.message || "Order Moved Successfully");
        } else {
          alert(res.data.message || "Failed to update Zoho Customer Id");
        }
      })
      .catch((err) => {
        console.log("error", err);
        alert("Something went wrong");
      });
  };
  return (
    <SubCard>
      <Grid container gap={2}>
        <Grid item sm={12} md={2}>
          <TextField
            label="Zoho Invoice Number"
            value={zohoInvoiceNumber}
            onChange={(e) => setZohoInvoiceNumber(e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item sm={12} md={3}>
          <Button
            variant="contained"
            onClick={updateOrder}
            fullWidth
            sx={{ height: "100%" }}
            disabled={zohoInvoiceNumber.trim() == ""}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </SubCard>
  );
}

export default MoveOrderBulkToProductCollection;
