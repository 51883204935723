import React, { useEffect, useState } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material"
import { Cancel as CancelIcon } from "@mui/icons-material"
import axios from "axios"
import { getDecryptDataFromLocal } from "../../utils/encrypt"


export default function OrderCancelModal({ open, handleClose, handleSubmit,orderDetails }) {
  const [selectedReason, setSelectedReason] = useState("")
  const [customReason, setCustomReason] = useState("")
  const [orderCancelReasonId, setOrderCancelReasonId] = useState(null)
  const [allCancelReasons, setAllCancelReasons] = useState([])
  const [cancelBtn, setCancelBtn] = useState(false)
  const phoneNumber = getDecryptDataFromLocal("phoneNumber")

  const handleReasonChange = (event) => {
    setOrderCancelReasonId(event.target.value)
  }

  const handleCustomReasonChange = (event) => {
    setCustomReason(event.target.value)
  }

  const onSubmit = () => {
    if (selectedReason === "Other" && !customReason.trim()) {
      alert("Please provide a custom reason for cancellation.")
      return
    }

    const finalReason = selectedReason === "Other" ? customReason : selectedReason
    handleSubmit(finalReason)
    handleClose()
  }


  const getCancelResons = () => {
    axios.get(process.env.REACT_APP_PATH + "/role4/getProductOrderCancellationReasons").then((res) => {
        if (res.data.success) {
            setAllCancelReasons(res.data.data)
        }
    }).catch((e) => {
        console.log("error", e)
    })
}


const orderCancel = () => {
    // console.log('sdsd', OrderId, orderCancelReasonId)
    setCancelBtn(true)
    axios
        .get(process.env.REACT_APP_PATH + `/role4/cancelOrder?orderId=${orderDetails?._id}&&phoneNumber=${phoneNumber}&&reasonId=${orderCancelReasonId}&&remarks=${customReason}`)
        .then((res) => {
            if(res.data.success){
                alert("Order cancel");
                handleSubmit()
                setCancelBtn(false)

            }else{
                alert(res.data?.message||'Someting went wrong');
            }
        }).catch((err) => {
            setCancelBtn(false)
            alert(err?.response?.data?.message || "something went wrong")
        })
};

useEffect(()=>{
    getCancelResons()
},[])


  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="cancel-order-dialog-title" sx={{minWidth:'400px'}}>
      <DialogTitle id="cancel-order-dialog-title">
        <CancelIcon color="error" style={{ marginRight: "8px", verticalAlign: "middle" }} />
        Cancel Order
      </DialogTitle>
      <DialogContent style={{minWidth:400}}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="cancel-reason-label">Reason for Cancellation</InputLabel>
          <Select
            labelId="cancel-reason-label"
            id="cancel-reason-select"
            value={orderCancelReasonId}
            onChange={handleReasonChange}
            label="Reason for Cancellation"
          >
            {allCancelReasons && allCancelReasons.map((reason) => (
              <MenuItem key={reason._id} value={reason.id}>
                {reason?.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
       
          <TextField
            fullWidth
            margin="normal"
            id="custom-reason"
            label="Custom Reason"
            type="text"
            variant="outlined"
            value={customReason}
            onChange={handleCustomReasonChange}
            multiline
            rows={2}
          />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={orderCancel} color="error" variant="contained" disabled={cancelBtn}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

