import React from 'react'
import { useState } from 'react'
import SubCard from '../../../ui-component/cards/SubCard';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useEffect } from 'react';
import axios from 'axios';
import { Autocomplete, Grid, TextField } from '@material-ui/core';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import MainCard from '../../../ui-component/cards/MainCard';
import { CSVLink } from 'react-csv';

let headersAll = [
    { label: "Order Id", key: 'orderId', },
    { label: "Packer Name", key: 'packerName', },
    { label: "Seat Number", key: 'tableNumber', },
    { label: "Packing Time", key: 'packingTime', },
    { label: "Order Weight", key: 'weight', },
    { label: "Packing Images 1", key: 'packingImage1', },
    { label: "Packing Images 2", key: 'packingImage2', },
    { label: "Packing Images 3", key: 'packingImage3', },
]

let headers = [
    { label: "Order Id", key: 'orderId', },
    { label: "Packing Time", key: 'packingTime' },
    { label: "Packing Images 1", key: 'packingImage1', },
    { label: "Packing Images 2", key: 'packingImage2', },
    { label: "Packing Images 3", key: 'packingImage3', },
]

function PackerNonCrmOrderList() {

    const [date, setDate] = useState({
        startDate: moment().clone().subtract(1, 'day').format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD')
    });
    const [packdata, setPackData] = useState([])
    const [loading, setLoading] = useState(false);
    const [packerId, setPackerId] = useState(null)
    const [list, setList] = useState([])
    const [packerName, setPackerName] = useState("")

    const loadAllpackers = () => {
        axios.post(`${process.env.REACT_APP_PATH}/fulfilment/orders/packersOrders`, {
            packerIdFromAdmin: packerId,
            startDate: date.startDate,
            endDate: date.endDate,
            allPackers: true,
            nonCrmOrder:true
        }).then(resp => {
            setLoading(false)
            console.log("response of fulfilment/orders/packersOrders", resp.data.body[0]?.data)
            if (resp.data.headers?.success){
                resp.data.body.forEach((item)=>{
                    item.packingImage1 = item?.packingImages[0]||''
                    item.packingImage2 = item?.packingImages[1]||''
                    item.packingImage3 = item?.packingImages[2]||''
                })
            setList(resp.data.body)
        }else {
            alert(resp?.data?.headers?.message);
        }
        }).catch(err => {
            alert(err?.response?.data?.headers?.message)
        })
    }

    const dataLoad = () => {
        setLoading(true);
        axios.post(`${process.env.REACT_APP_PATH}/fulfilment/orders/packersOrders`, {
            packerIdFromAdmin: packerId,
            startDate: date.startDate,
            endDate: date.endDate,
            allPackers: false,
            nonCrmOrder:true
        }).then(resp => {
            setLoading(false)
            console.log("response of fulfilment/orders/packersOrders", resp.data.body[0]?.data)
            if (resp.data.headers?.success){
                resp.data.body.forEach((item)=>{
                    item.packingImage1 = item?.packingImages[0]||''
                    item.packingImage2 = item?.packingImages[1]||''
                    item.packingImage3 = item?.packingImages[2]||''
                })
            setList(resp.data.body)
        }else {
            alert(resp?.data?.headers?.message);
        }
        }).catch(err => {
            alert(err?.response?.data?.headers?.message)
        })
    }

    const handleSelect = (e, v) => {
        if (v !== null) {
            setList([])
            setPackerName(`${v?.name}(${v?.userName})`)
            setPackerId(v?._id)
        } else {
            setPackerId(null)
        }
    }

    useEffect(()=>{
        // dataLoad()
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_PATH}/fulfilment/orders/packersList`
        }).then(res => {
            if (res?.data?.headers?.success) {
                setPackData(res?.data?.body);
                console.log("data of orders/packersList", res?.data?.body)
            } else {
                alert(res?.data?.headers?.message || 'something went wrong');

            }
        }).catch(err => {
            alert(err?.response?.data?.headers?.message || 'something went wrong');

        })
    },[])

    useEffect(()=>{
        if(packerId == null || packerId == undefined){
            loadAllpackers()
        }
    },[packerId])

    return (
        <>
            <MainCard title="" >
                <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} lg={2}>
                <Autocomplete
                        id="tags-outlined"
                        options={packdata}
                        getOptionLabel={(option) => option.name+`(${option.userName})`}
                        filterSelectedOptions
                        onChange={handleSelect}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select Packer"
                                placeholder="Select Packer"
                            />
                        )}
                    />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <TextField
                            id="datetime-local"
                            label="Packing Start Date"
                            type="date"
                            defaultValue={date.startDate}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{ width: "100%", }}
                            size="small"
                            onChange={(e) => setDate({ ...date, startDate: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <TextField
                            id="datetime-local"
                            label="Packing End Date"
                            type="date"
                            defaultValue={date.endDate}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{
                                width: "100%",
                            }}
                            onChange={(e) => setDate({ ...date, endDate: e.target.value })}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <LoadingButton size="small" loading={loading}
                            onClick={packerId == null || packerId == undefined ? loadAllpackers : dataLoad}
                            variant="contained" >Submit</LoadingButton>
                    </Grid>
                    {list?.length > 0 &&
                        <Grid item xs={12} lg={2}>
                            <CSVLink
                                filename={`${packerId == null ? "allpackers" : packerName}.csv`}
                                data={list.map(item => {
                                    return {
                                        ...item,
                                        packingTime: moment(item?.createdAt).format('YYYY-MM-DD hh:mm:ss A'),
                                    }
                                })}
                                headers={packerId == null ? headersAll : headers}
                                style={{
                                    textDecoration: 'none',
                                    border: '1px solid #999',
                                    padding: '10px 20px',
                                    backgroundColor: '#95c7f1'
                                }}
                            >
                                {"Download"}
                            </CSVLink>
                        </Grid>
                    }
                    {(loading) ?
                        <Typography style={{ marginTop: "20px" }} variant="h4" align="center">Loading....</Typography>
                        :
                        <TableContainer style={{ maxHeight: 440 }}>
                            {list?.length > 0 &&
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Order Id</TableCell>
                                            {packerId == null && <TableCell align="left">Packer Name</TableCell>}
                                            {packerId == null && <TableCell align="left">Seat Number</TableCell>}
                                            <TableCell align="left">Packing Time</TableCell>
                                            <TableCell align="left" >Group Order Id</TableCell>
                                            <TableCell align="left" >Order Weight</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {list?.map((pack, idx) => {
                                            console.log('createdAt',pack.createdAt)
                                            return (
                                                <TableRow sx={{ py: 3 }} hover role="checkbox" tabIndex={-1} key={pack?._id}>
                                                    <TableCell align="left" >{pack?.orderId}</TableCell>
                                                    {packerId == null && <TableCell align="left" >{pack?.packerName}</TableCell>}
                                                    {packerId == null && <TableCell align="left" >{pack?.tableNumber}</TableCell>}
                                                    <TableCell align="left" >
                                                        {moment(pack?.createdAt).format('YYYY-MM-DD hh:mm:ss A')}
                                                        </TableCell>
                                                    <TableCell align="left" >{pack?.groupOrderId ? pack?.groupOrderId : ""}</TableCell>
                                                    <TableCell align="left" >{pack?.weight}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            }
                        </TableContainer>
                    }
                </Grid>
            </MainCard>
        </>
    )
}

export default PackerNonCrmOrderList